@import url('https://fonts.googleapis.com/css?family=Open+Sans');

 
 body {
     color: white !important;
     font-family: Arial, sans-serif;
     background-color: #181818 !important;
 }
 
  /* Header */
 
 #main-title {
     padding: 15px;
     font-size: 3em;
     font-style: italic;
     border-bottom: solid #0031c4 2px;
 }
 
 #main-title img {
     width: 400px;
 }
 
  /* Movies */
 
 .row {
     padding: 15px;
 }
 
 .movie-name {
     margin-top: 8px;
 }
 
  /* Movies not favorites */
 
 .movies {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-around;
     overflow-x: hidden;
     overflow-y: auto;
     scroll-snap-type: mandatory;
     height: 700px;
 }
 
 .movie-not-fav {
     padding: 15px;
 }
 
 .movie-not-fav img {
     width: 200px;
     height: 300px;
     cursor: pointer;
 }
 
 .movie-not-fav .add-fav {
     position: absolute;
     z-index: 1;
     opacity: 0;
     text-align: center;
     margin-left: 20px;
     margin-top: 130px;
 }
 
 .movie-not-fav:hover .add-fav {
     opacity: 1;
     transition: 1s;
 }
 
 .movie-not-fav:hover img{
     transform: scale(1.05);
     transition: transform .1s;
 }
 
 .button-add-fav {
     border: none;
     border-radius: 10px;
     color: white;
     background-color: rgba(0, 0, 0, 0.7);
 }
 
  /* Movies favorites */
 
 .movie-fav {
     padding: 10px;
 }
 
 .movie-fav img {
     width: 150px;
     height: 220px;
 }
 
 .fav{
     overflow-x: hidden;
     overflow-y: auto;
     scroll-snap-type: mandatory;
     height: 700px;
     border-left: solid black 2px;
 }
 
 .favorites img {
     width: 300px;
     margin-bottom: 10px;
 }
 
 .movie-fav:hover img{
     transform: scale(1.05);
     transition: transform .1s;
 }
 
 .movie-fav .cross-remove-fav {
     position: absolute;
     margin-left: 45px;
     margin-top: -10px;
     z-index: 1;
     opacity: 0;
 }
 
 .movie-fav:hover .cross-remove-fav {
     opacity: 1;
 }
 
  /* Scroll bar movies not favorites */
 
 .movies::-webkit-scrollbar {
     width: 12px;
 }
 
 .movies::-webkit-scrollbar-track {
     background: #0f0f0f;
     border-radius: 10px;
 }
 
 .movies::-webkit-scrollbar-thumb {
     background-color: #3f3f3f;
     border-radius: 10px;
 }
 
  /* Scroll bar movies favorites */
 
 .fav::-webkit-scrollbar {
     width: 12px;
 }
 
 .fav::-webkit-scrollbar-track {
     background: #0f0f0f;
     border-radius: 10px;
 }
 
 .fav::-webkit-scrollbar-thumb {
     background-color: #3f3f3f;
     border-radius: 10px;
 }
 
  /* Footer */
 
 footer {
     border-top: solid #262626 2px;
     padding: 10px;
 }
 
  /* Responsive */
 
 @media screen and (max-width: 1809px) {
 
     .favorites img {
         width: 240px;
     }
 }
 
 @media screen and (max-width: 1520px) {
 
     .favorites img {
         width: 230px;
     }
 }
 
 @media screen and (max-width: 1400px) {
 
     .favorites img {
         width: 210px;
     }
 }
 
 @media screen and (max-width: 1300px) {
 
     .favorites img {
         width: 190px;
     }
 }
 
 @media screen and (max-width: 1236px) {
 
     .favorites img {
         width: 180px;
     }

     .movie-fav img {
        width: 120px;
        height: 180px;
    }

    .cross-remove-fav {
       margin-left: 35px !important;
    } 
 }
 
 @media screen and (max-width: 1123px) {
 
     .favorites img {
         width: 150px;
     }

     .movie-fav img {
        width: 120px;
        height: 180px;
    }

    .cross-remove-fav {
       margin-left: 35px !important;
    } 
 }
 
 @media screen and (max-width: 945px) {

    .movie-not-fav img {
        width: 180px;
        height: 250px;
    }
 
     .favorites img {
         width: 110px;
     }

     .movie-fav img {
        width: 120px;
        height: 170px;
    }

    .cross-remove-fav {
       margin-left: 30px !important;
    }    
 }
 
 @media screen and (max-width: 767px) {

    .movies {
        width: 65% !important;
    }

    .movie-not-fav img {
        width: 150px;
        height: 210px;
    }

    .fav {
        width: 35% !important;
    }

    .movie-fav img {
        width: 120px;
        height: 170px;
    }

    .cross-remove-fav {
       margin-left: 30px !important;
    }    
 
     .favorites img {
         width: 160px;
     }
 }
 
 @media screen and (max-width: 725px) {

    .movie-not-fav img {
        width: 150px;
        height: 210px;
    }

    .movies {
        width: 65% !important;
    }

    .fav {
        width: 35% !important;
    }

    .movie-fav img {
        width: 120px;
        height: 170px;
    }

    .cross-remove-fav {
       margin-left: 30px !important;
    }
 
     .favorites img {
         width: 230px;
     }
 }
 
 @media screen and (max-width: 690px) {

    .movies {
        width: 65% !important;
    }

    .movie-not-fav img {
        width: 100px;
        height: 150px;
    }

    .fav {
        width: 35% !important;
    }

    .movie-fav img {
        width: 100px;
        height: 150px;
    }

    .cross-remove-fav {
       margin-left: 22px !important;
    }
 
     .favorites img {
         width: 190px;
     }
 }
 
 @media screen and (max-width: 590px) {

    .movies {
        width: 65% !important;
    }

    .movie-not-fav img {
        width: 100px;
        height: 150px;
    }

    .fav {
        width: 35% !important;
    }

    .movie-fav img {
        width: 100px;
        height: 150px;
    }

    .cross-remove-fav {
       margin-left: 20px !important;
    }
 
     .favorites img {
         width: 150px;
     }
 }

 @media screen and (max-width: 567px) {

    .movies {
        width: 65% !important;
    }

    .movie-not-fav {
        padding: 10px;
    }

    .movie-not-fav img {
        width: 90px;
        height: 140px;
    }

    .fav {
        width: 35% !important;
    }

    .movie-fav img {
        width: 90px;
        height: 140px;
    }

    .cross-remove-fav {
       margin-left: 20px !important;
    }
 
     .favorites img {
         width: 150px;
     }
 }

 @media screen and (max-width: 480px) {

    .movies {
        width: 65% !important;
    }

    .movie-not-fav {
        padding: 5px;
    }

    .movie-not-fav img {
        width: 70px;
        height: 120px;
    }

    .fav {
        width: 35% !important;
    }

    .movie-fav img {
        width: 70px;
        height: 120px;
    }

    .cross-remove-fav {
       margin-left: 10px !important;
    }
 
     .favorites img {
         width: 150px;
     }
 }
 